import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from './auth.service';

/**
 * Whether the current user can access to a view or not
 */
export const authGuard: CanActivateFn = (_route, { url }) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const isUserLogged = authService.loggedIn();

  if (!isUserLogged) {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang') || '';

    authService.redirectUrl = url;
    const redirectUrlBase = authService.isValidHubDomain() && router.url !== '/hub' ? '/hub' : '/login';
    const redirectUrl = redirectUrlBase === '/login' && lang ? `${redirectUrlBase}?lang=${lang}` : redirectUrlBase;

    return router.parseUrl(redirectUrl);
  }

  return isUserLogged;
};
