import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthService } from '../shared-components/ng-login/auth/auth.service';
import { SocialLoginService } from './social-login.service';

export const loginRedirectionGuardService: CanActivateFn = () => {
  const authService = inject(AuthService);
  const socialLoginService = inject(SocialLoginService);

  if (authService.loggedIn()) {
    socialLoginService.loginResponse.next(true);
  }

  return true;
};
