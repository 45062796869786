import { HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SnackbarStatus } from '../definitions/snackbar-status.enum';
import { environment } from '../environments/environment';
import { getStorageObject } from '../utils/storage-manager';
import { CustomSnackbarService } from './custom-snackbar.service';

/**
 * HttpError interceptor
 *
 * It intercepts HTTP calls to catch the errors and shows the error messages
 */
export const httpErrorInterceptor: HttpInterceptorFn = (req, next): Observable<HttpEvent<any>> => {
  const injector = inject(Injector);
  const router = inject(Router);

  return next(req).pipe(
    catchError((err) => {
      const translateService = injector.get(TranslateService);
      const snackbarService = injector.get(CustomSnackbarService);
      const message =
        err.status === 401 && err.error.message?.includes('JWT')
          ? 'Your session has expired'
          : err.error.errors || err.error.message || 'errors.generic';

      if (
        !req.url.endsWith('online-ping') &&
        !message.startsWith('The identity document already exists in other profile')
      ) {
        // We wrap this in a timeout to be able to stop it within a 'catch' where we don't want
        // the toast to show
        err.timeout = setTimeout(() => {
          snackbarService.present(
            translateService.instant((err.error.errors ? 'Errors.' : '') + message),
            SnackbarStatus.Error
          );
        }, 100);

        if (req.url.endsWith('login-euroinnova')) {
          setTimeout(() => {
            if (getStorageObject(environment.needSubscriptionStorage)) {
              router.navigateByUrl('/subscription/checkout');
            } else if (getStorageObject(environment.idSimoToEnrollStorage)) {
              router.navigateByUrl(`/course/${getStorageObject(environment.idSimoToEnrollStorage)}checkout`);
            } else {
              router.navigateByUrl('/login');
            }
          }, 3000);
        }
      }

      return throwError(() => err);
    })
  );
};
