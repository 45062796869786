import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../shared-components/ng-login/auth/auth.service';

export const activeSessionGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.loggedIn() ? router.parseUrl('/') : true;
};
