import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';
import { JwtModule } from './jwt/jwt.module';

/**
 * TODO: this module is included here because of some Angular changes that broke the original ng-login component.
 * Revisit and fix in the future
 */

/**
 * Method to get the domains from the environment
 */
const getDomains = (): (string | RegExp)[] => {
  let baseDomain = environment.name;
  if (environment.apiPort) {
    baseDomain += ':' + environment.apiPort;
  }

  return [new RegExp('.*.?' + baseDomain)];
};

/**
 * NG-Login module
 */
@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        domainsGetter: getDomains
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [],
  providers: [AuthService]
})
/**
 * Login module class
 */
export class LoginModule {}
