import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { CustomerService } from './customer.service';

/**
 * Guard for modules
 * @param route
 * @param state
 */
export const moduleGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const module = route.data.module;

  const customerService = inject(CustomerService);
  if (!customerService.isCustomerModuleEnabled(module)) {
    return router.parseUrl('overview');
  }

  return true;
};
