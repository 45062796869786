import { bootstrapApplication } from '@angular/platform-browser';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    dsn: 'https://f41d89af1972487382d1a2ddf00505fc@o4505226924064768.ingest.sentry.io/4505228273123328',
    environment: environment.name,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: environment.tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate: environment.replaysSessionSampleRate,
    replaysOnErrorSampleRate: environment.replaysOnErrorSampleRate
  });
}

bootstrapApplication(AppComponent, appConfig)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
