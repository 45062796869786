import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserService } from '../../../services/user.service';
import { getStorageObject } from '../../../utils/storage-manager';
import { AuthService } from '../auth/auth.service';
import { JWT_OPTIONS } from './jwt-options.token';

/**
 * Header appender method
 *
 * @param request Request to append the domain to
 */
function addHeaderToNative(request: HttpRequest<any>) {
  return request.clone({
    setHeaders: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Bypass-Tunnel-Reminder': 'true'
    }
  });
}

/**
 * JWT interceptor
 *
 * It intercepts HTTP calls to append the JWT token. Also manages the token refresh.
 */
export const jwtInterceptor: HttpInterceptorFn = (request, next) => {
  const config: any = inject(JWT_OPTIONS);
  // const inj = inject(Injector);
  const router = inject(Router);
  const authService = inject(AuthService);
  const userService = inject(UserService);

  /**
   * JWT header name
   */
  const headerName: string = config.headerName || 'Authorization';

  /**
   * JWT authentication scheme (e.g. Bearer)
   */
  const authScheme: string = config.authScheme || config.authScheme === '' ? config.authScheme : 'Bearer ';

  /**
   * Variable to know if the access token is refreshing or not
   */
  let isRefreshing = false;

  /**
   * Subject to manage the refresh access token
   */
  const refreshTokenSubject = new BehaviorSubject<any>(null);

  /**
   * Function to manage the case of refresh the authorization token
   */
  function handle401Error(request: HttpRequest<any>) {
    if (!isRefreshing) {
      isRefreshing = true;
      refreshTokenSubject.next(null);

      return userService.refreshAuthToken().pipe(
        switchMap((token: any) => {
          isRefreshing = false;
          refreshTokenSubject.next(token.accessToken);

          return next(addToken(request, token.accessToken));
        })
      );
    }

    return refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next(addToken(request, token)))
    );
  }

  /**
   * Token appender function
   *
   * @param request Request to append the token to
   * @param token JWT Token
   */
  function addToken(request: HttpRequest<any>, token: string) {
    if (token) {
      return request.clone({
        setHeaders: {
          [headerName]: `${authScheme}${token}`
        }
      });
    } else {
      return request.clone();
    }
  }

  const accessToken = getStorageObject(environment.accessTokenStorage);
  const refreshAccessToken = getStorageObject(environment.accessRefreshTokenStorage);

  if (request.url.endsWith('refresh-token')) {
    request = addToken(request, refreshAccessToken);
  } else {
    request = addToken(request, accessToken);
  }

  if (!environment.production) {
    request = addHeaderToNative(request);
  }

  // We should get the AuthService like to avoid circular dependencies
  // authService = inj.get(AuthService);

  return next(request).pipe(
    catchError((error) => {
      // If token is expired...
      if (error.status === 401) {
        if (!request.url.endsWith('refresh-token') && (refreshAccessToken !== null || true)) {
          return handle401Error(request);
        }

        // If the refresh token request fail redirect to log in
        isRefreshing = false;
        authService.logout();
        router.navigateByUrl('/login?exp=1', { replaceUrl: true });

        return throwError(() => error);
      }

      return throwError(() => error);
    })
  );
};
